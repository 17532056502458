import styled from 'styled-components';
import * as vars from '../../../constants';

export const TopContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-direction: column;
  @media (min-width: ${vars.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const ArticleContainer = styled.div`
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
  @media (min-width: ${vars.breakpoints.md}) {
    height: ${p => (p.large ? '500px' : '450px')};
  }
`;

// Bottom article
export const FeaturedArticleContainer = styled.div`
  grid-row: 1 / span 6;
  @media (min-width: ${vars.breakpoints.md}) {
    margin-top: 20px;
    grid-column: 3 / span 4;
    grid-row: 1 / span 9;
    z-index: 2;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-column: 2 / span 4;
    grid-row: 1 / span 9;
  }
`;

export const ArticleInfo = styled.div`
  background: ${vars.branding.grey2};
  color: ${vars.branding.secondary};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row: 7 / span 4;
  padding: 10px;
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 0 20px 0 25%;
    line-height: 1.5;
    grid-column: 6 / span 6;
    grid-row: 1 / span 10;
    z-index: 1;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    padding: 0 20px 0 20%;
    grid-column: 5 / span 7;
  }
`;
