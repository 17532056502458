import styled, { css } from 'styled-components';
import * as vars from '../../constants';
import arrowIcon from '../../images/arrow.svg';

export const CarouselGrid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 80px;
`;

export const CarouselContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const NavControlsOverlay = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 85%;
`;

export const ButtonContainer = styled.button`
  height: 100%;
  width: 100px;
  border: none;
  background: rgba(0, 0, 0, 0);
  display: flex;
  ${p =>
    p.left &&
    css`
      justify-content: flex-start;
      div {
        transform: scaleX(-1);
      }
    `};

  ${p =>
    p.right &&
    css`
      justify-content: flex-end;
    `};

  &:hover {
    cursor: pointer;
  }
`;

export const ArrowIcon = styled.div`
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  margin: 0;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  background: url(${arrowIcon}) center center no-repeat #fff;
  background-size: 30%;
  @media (min-width: ${vars.breakpoints.md}) {
    margin: ${p => (p.margin ? p.margin : 0)};
  }
`;

export const PhotosContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PhotoDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${p => p.imgSrc}) center center no-repeat
    ${vars.branding.grey1};
  background-size: cover;
  transition: opacity 500ms ease-out;
  opacity: ${p => (p.show ? 1 : 0)};
`;

export const CarouselInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => (p.color ? p.color : vars.branding.secondary)};
  @media (min-width: ${vars.breakpoints.md}) {
    margin: 0;
    justify-content: flex-end;
  }
`;
