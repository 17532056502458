import styled from 'styled-components';
import React from 'react';
import { Link } from 'gatsby';
import * as vars from '../../constants';
import arrow from '../../images/arrow.svg';

export const CTAGatsbyLink = styled(props => <Link {...props} />)`
  display: block;
  position: relative;
  width: 100%;
  padding: 25px 0;
  background: ${vars.branding.secondary};
  text-decoration: none;
  margin: ${p => (p.margin ? p.margin : 0)};
  line-height: 1.5;
`;

export const LinkTextContainer = styled.div`
  color: #fff;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 25%;
  text-align: right;
  z-index: 3;
  transition: all 250ms ease-out;
`;

export const LinkArrowContainer = styled.div`
  background: url(${arrow}) 50% center no-repeat ${vars.branding.secondaryDark};
  width: 30px;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 250ms ease-out;
  z-index: 2;

  ${CTAGatsbyLink}:hover & {
    width: 100%;
    background-position-x: 90%;
  }
`;
