import * as vars from '../constants';

export const generateReactKey = (namespace, idContent) => {
  const flatString = idContent.toString().replace(' ', '');

  return flatString.length >= 10
    ? `${namespace}_${flatString.substring(0, 9)}`
    : `${namespace}_${flatString}`;
};

export const hiddenSmall = () => {
  return `display: none;
    @media (min-width: ${vars.breakpoints.md}) {
      display: block;
    }`;
};

export const visibleSmall = () => {
  return `display: block;
  @media (min-width: ${vars.breakpoints.md}) {
    display: none;
  }`;
};
