import React from 'react';
import * as el from './FeaturedRecruiting.styled';
import {
  CommonGrid,
  LinkBTN,
  HR,
} from '../../shared-styled-components/globals.styled';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import office1 from '../../../images/Bottom_1.jpg';
import office2 from '../../../images/Bottom_2.jpg';
import office3 from '../../../images/Bottom_3.jpg';
import { Heading } from '../../shared-styled-components/typography';
export default function FeaturedRecruiting() {
  return (
    <CommonGrid as="section">
      <el.ImageContainer1 bgImg={office1} />
      <el.InfoContainer>
        <el.FlexContainer>
          <HR width="25px" margin="0 15px 0 0" />
          <Heading as="h4" capitalized>
            <FormattedMessage id="FeaturedRecruiting.smallHeading" />
          </Heading>
        </el.FlexContainer>
        <Heading as="h2" large>
          <FormattedMessage id="FeaturedRecruiting.heading" />
        </Heading>
        <Heading medium>
          <FormattedHTMLMessage id="FeaturedRecruiting.subheading" />
        </Heading>
        <el.FlexContainer>
          <LinkBTN to="/jobs/">
            <FormattedMessage id="FeaturedRecruiting.link1" />
          </LinkBTN>
          <LinkBTN className="inverseColor" to="/team/">
            <FormattedMessage id="FeaturedRecruiting.link2" />
          </LinkBTN>
        </el.FlexContainer>
      </el.InfoContainer>
      <el.ImageContainer2 bgImg={office2} />
      <el.ImageContainer3 bgImg={office3} />
    </CommonGrid>
  );
}
