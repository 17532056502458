import styled from 'styled-components';
import * as vars from '../../../constants';

export const CopyContainer = styled.div`
  grid-column: 1 / span 12;
  grid-row: 1 / span 6;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 2 / span 4;
    grid-row: 1 / span 4;
  }

  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 4 / span 3;
    grid-row: 1 / span 4;
    max-width: 400px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const CarouselContainer = styled.div`
  z-index: 2;
  grid-column: 1 / span 12;
  grid-row: 6 / span 7;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 7 / span 5;
    grid-row: 1 / span 11;
  }
  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 8 / span 3;
    grid-row: 1 / span 11;
  }
`;

export const FauxFooter = styled.div`
  grid-column: 1 / span 12;
  grid-row: 9 / span 4;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 1 / span 12;
    grid-row: 10 / span 2;
  }
  background: ${vars.branding.secondary};
  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 1 / span 12;
    grid-row: 8 / span 4;
  }
`;
