import styled, { css } from 'styled-components';
import * as vars from '../../constants';

export const Heading = styled.h3`
  margin: ${p => (p.margin ? p.margin : '10px 0')};
  font-size: ${p =>
    p.large ? '2rem' : p.medium ? '1.5rem' : p.small ? '0.75rem' : '1rem'};
  font-weight: ${p => (p.light ? 300 : p.bold ? 700 : 400)};
  color: ${p => (p.color ? p.color : vars.branding.black)};
  line-height: ${p => (p.spacedOut ? 1.5 : 1)};
  ${p =>
    p.capitalized &&
    css`
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 0.75rem;
      font-weight: 700;
      color: ${vars.branding.grey1};
    `}
`;

export const P = styled.p`
  font-size: ${p => (p.large ? '1.15rem' : '1rem')};
  margin: ${p => (p.margin ? p.margin : '5px 0')};
  line-height: ${p => (p.spacedOut ? 2 : 1)};
`;
