import styled from 'styled-components';
import * as vars from '../../constants';

export const CarouselSlideContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: url(${p => p.bgImg}) center center no-repeat;
  background-size: cover;
  grid-row: 6 / span 6;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: ${p => (p.show ? 1 : 0)};
  z-index: ${p => (p.show ? 5 : 1)};
  transition: opacity 500ms ease-out;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 7 / span 5;
    grid-row: 1 / span 10;
  }

  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 5 / span 7;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CTAContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 100%;
  @media (min-width: ${vars.breakpoints.lg}) {
    width: 250px;
    margin: 0 -50px 0 15px;
  }
`;

export const InfoContainer = styled.div`
  padding: 20px;
  color: ${vars.branding.grey3};
  background-color: ${p => (p.showBg ? `rgba(0, 0, 0, 0.15)` : `transparent`)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (min-width: ${vars.breakpoints.lg}) {
    flex-direction: row;
  }
`;
