import React, { useRef, useState, useEffect } from 'react';
import UseInterval from '../../hooks/UseInterval.hook';
import * as el from './Carousel.styled';
import { HR } from '../shared-styled-components/globals.styled';
import { generateReactKey } from '../../services/base.service';
import CarouselSlide from '../CarouselSlide/CarouselSlide.component';
import { FormattedMessage } from 'react-intl';
import { Heading } from '../shared-styled-components/typography';

export default function Carousel(props) {
  const carouselRef = useRef(null);
  const totalSlides = props.slides.length;

  const [currentSlide, setSlide] = useState(0);
  const [timerIsRunning, setTimerIsRunning] = useState(7000);

  UseInterval(() => handleNextClick(), timerIsRunning);

  useEffect(() => {
    initListeners();
  }, []);

  const handlePreviousClick = () => {
    currentSlide === 0 ? setSlide(totalSlides - 1) : setSlide(currentSlide - 1);
  };

  const handleNextClick = () => {
    currentSlide === totalSlides - 1 ? setSlide(0) : setSlide(currentSlide + 1);
  };

  const initListeners = () => {
    carouselRef.current.addEventListener('mouseenter', () =>
      setTimerIsRunning(null)
    );
    carouselRef.current.addEventListener('mouseleave', () =>
      setTimerIsRunning(7000)
    );
  };

  return (
    <>
      <el.CarouselGrid>
        <el.CarouselContainer ref={carouselRef}>
          <el.NavControlsOverlay>
            <el.ButtonContainer left onClick={() => handlePreviousClick()}>
              <el.ArrowIcon margin="0 0 0 -20px" />
            </el.ButtonContainer>
            <el.ButtonContainer right onClick={() => handleNextClick()}>
              <el.ArrowIcon margin="0 -20px 0 0" />
            </el.ButtonContainer>
          </el.NavControlsOverlay>
          <el.PhotosContainer>
            {props.slides.map((slide, i) => (
              <CarouselSlide
                key={generateReactKey(`slide_`, i)}
                bgImg={slide.bgImg}
                ctaLink={slide.ctaLink}
                ctaLinkText={slide.cta}
                show={currentSlide === i}
              >
                {slide.heading ? (
                  <Heading large bold color="#fff" margin="0">
                    <FormattedMessage id={slide.heading} />
                  </Heading>
                ) : null}
                {slide.subHeading ? (
                  <Heading medium color="#fff" margin="0 0 10px 0">
                    <FormattedMessage id={slide.subHeading} />
                  </Heading>
                ) : null}
              </CarouselSlide>
            ))}
          </el.PhotosContainer>
        </el.CarouselContainer>
        <el.CarouselInfoContainer color={props.color}>
          {currentSlide + 1}
          <HR width="30%" margin="0 10px" borderColor={props.color} />
          {totalSlides}
        </el.CarouselInfoContainer>
      </el.CarouselGrid>
    </>
  );
}
