import React from 'react';
import { Heading } from '../shared-styled-components/typography';
import * as el from './ArticleCard.styled';
import { FormattedMessage } from 'react-intl'

export default function ArticleCard(props) {
  return (
    <el.ArticleCardContainer bgImg={props.bgUrl}>
      <el.ShadowOverlay>
        <el.InfoContainer>
          <el.TopRuler />
          <Heading as="h4" small color="#fff">
            <FormattedMessage id="ArticleCard.smallHeading" />
          </Heading>
          <Heading medium color="#fff">
            <FormattedMessage id="ArticleCard.heading" />
          </Heading>
          <el.CardLink to="/articles/">
            <FormattedMessage id="ArticleCard.link" />
          </el.CardLink>
        </el.InfoContainer>
      </el.ShadowOverlay>
    </el.ArticleCardContainer>
  );
}
