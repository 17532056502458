import styled from 'styled-components';
import * as vars from '../../constants';

export const HomeIntroGrid = styled.section`
  display: grid;
  width: 100%;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10, 1fr);
  @media (min-width: ${vars.breakpoints.md}) {
    height: 90vh;
    grid-template-rows: repeat(12, 1fr);
    grid-template-columns: 100px repeat(10, 1fr) 100px;
  }
`;

export const IntactLogo = styled.img`
  margin-bottom: 24px;
`;

export const IntroCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  grid-row: 1 / span 3;
  line-height: 1.2;
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 0;
    grid-column: 2 / span 4;
    grid-row: 1 / span 12;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-column: 2 / span 4;
    grid-row: 3 / span 9;
  }

  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 2 / span 2;
  }

  h2 {
    font-size: 1.25rem;
    @media (min-width: ${vars.breakpoints.md}) {
      font-size: 1.75rem;
    }
    @media (min-width: ${vars.breakpoints.lg}) {
      font-size: 2rem;
    }
  }
`;

export const TopContent = styled.div``;
export const BottomContent = styled.div``;

export const HeroImageContainer = styled.div`
  background: url(${p => p.bgImg}) center center no-repeat;
  background-size: cover;
  grid-row: 6 / span 6;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 7 / span 5;
    grid-row: 1 / span 10;
  }

  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 5 / span 7;
  }
`;

export const BackgroundUnderlay = styled.div`
  position: relative;
  display: none;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 8 / span 5;
    grid-row: 2 / span 11;
    background-color: ${vars.branding.paleGreen};
    z-index: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

export const HeroInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  padding: 25px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0.5) 87%,
    rgba(0, 0, 0, 0.65) 100%
  );
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 10px;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    flex-direction: row;
    padding: 25px;
  }
`;

const HeroTextConstants = `
position: relative;
z-index: 10;
font-size: 3rem;
font-weight: 700;
color: ${vars.branding.secondary};
transition: all 2s ease-out;

@media (min-width: ${vars.breakpoints.md}) {
  font-size: 4.5rem;
}
`;

export const HeroText1 = styled.h4`
  ${HeroTextConstants}
  grid-row: 1 / span 1;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${vars.branding.secondary};
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 2 / span 11;
  }
  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 4 / span 9;
  }
`;

export const HeroText2 = styled.h4`
  ${HeroTextConstants}
  grid-row: 3 / span 1;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 3 / span 8;
    grid-row: 3 / span 2;
  }
  @media (min-width: ${vars.breakpoints.xl}) {
    grid-column: 7 / span 5;
  }
`;

export const HeroTextGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 50px);
  overflow: hidden;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-template-columns: 100px repeat(10, 1fr) 100px;
  }
`;

export const HeroTextUnderlay = styled.div`
  background-color: ${vars.branding.paleGreen};
  grid-column: 8 / span 5;
  grid-row: 1 / span 4;
`;
