import React from 'react';
import * as el from './FeaturedArticles.styled';
import ArticleCard from '../../ArticleCard/ArticleCard.component';
import { Heading, P } from '../../shared-styled-components/typography';
import {
  CommonGrid,
  GlobalWrapper,
} from '../../shared-styled-components/globals.styled';
import { branding } from '../../../constants';

import articleImg1 from '../../../images/Blog_1.jpg';
import articleImg2 from '../../../images/Blog_2.jpg';
import articleImg3 from '../../../images/Blog_3.jpg';
import articleImg4 from '../../../images/Blog_4.jpg';
import focus from '../../../images/Focus_1.jpg';

export default function FeaturedArticles() {
  const dummyArticles = [
    {
      id: '23412fqfx',
      category: 'design and behaviorial sciences',
      title: '5 steps to uptimize your user research',
      bgUrl: articleImg1,
    },
    {
      id: '234gsdfgfqfy',
      category: 'ux & ui',
      title: "What's the difference?",
      bgUrl: articleImg2,
    },
    {
      id: '234glskdjfz',
      category: 'front-end development',
      title: 'React or Angular? Just not Angularjs',
      bgUrl: articleImg3,
    },
    {
      id: '234gddassdjfv',
      category: 'corporate',
      title: 'What is a lab?',
      bgUrl: articleImg4,
    },
  ];

  return (
    <>
      <GlobalWrapper>
        <el.TopContainer>
          {dummyArticles.map(article => (
            <el.ArticleContainer large key={article.id}>
              <ArticleCard
                category={article.category}
                title={article.title}
                bgUrl={article.bgUrl}
              />
            </el.ArticleContainer>
          ))}
        </el.TopContainer>
        <CommonGrid>
          <el.FeaturedArticleContainer large>
            <ArticleCard bgUrl={focus} />
          </el.FeaturedArticleContainer>
          <el.ArticleInfo>
            <Heading medium color={branding.secondary}>
              Our data-driven approach puts the decision-making process of the
              user at the centre of every solution.
            </Heading>
            <P large>
              We craft conversion-focused digital products that engage with our
              audience and sky-rocket usability and user expereince.
            </P>
          </el.ArticleInfo>
        </CommonGrid>
      </GlobalWrapper>
    </>
  );
}
