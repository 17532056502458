import styled from 'styled-components';
import * as vars from '../../../constants';
export const ImageContainer1 = styled.div`
  display: none;
  @media (min-width: ${vars.breakpoints.md}) {
    display: block;
    grid-column: 1 / span 6;
    grid-row: 1 / span 6;
    background: url(${p => p.bgImg}) center center no-repeat;
    background-size: cover;
  }
`;
export const InfoContainer = styled.div`
  grid-row: 1 / span 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 0;
    grid-column: 8 / span 3;
  }
`;
export const ImageContainer2 = styled.div`
  display: none;
  @media (min-width: ${vars.breakpoints.md}) {
    display: block;
    grid-column: 2 / span 3;
    grid-row: 4 / span 9;
    background: url(${p => p.bgImg}) center center no-repeat;
    background-size: cover;
  }
`;
export const ImageContainer3 = styled.div`
  grid-row: 7 / span 6;
  background: url(${p => p.bgImg}) center center no-repeat;
  background-size: cover;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 6 / span 7;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;
