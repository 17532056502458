import React from 'react';
import * as el from './CTALink.styled';
export default function CTALink(props) {
  return (
    <el.CTAGatsbyLink to={props.ctaLink} margin={props.margin}>
      <el.LinkTextContainer>{props.children}</el.LinkTextContainer>
      <el.LinkArrowContainer />
    </el.CTAGatsbyLink>
  );
}
