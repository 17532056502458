import React from 'react';
import { CommonGrid, HR } from '../../shared-styled-components/globals.styled';
import { Heading, P } from '../../shared-styled-components/typography';
import { branding } from '../../../constants';
import * as el from './FeaturedEvents.styled';
import Carousel from '../../Carousel/Carousel.component';

import home1 from '../../../images/Hero_1.jpg';
import home2 from '../../../images/Hero_2.jpg';
import home3 from '../../../images/Hero_3.jpg';
import home4 from '../../../images/Hero_4.jpg';

export default function FeaturedEvents() {
  const carouselData = [
    {
        bgImg: home1,
        heading: 'FeaturedEvents.Carousel.slide1.heading',
        subHeading: 'FeaturedEvents.Carousel.slide1.subheading',
        ctaUrl: '/home/',
        cta: 'FeaturedEvents.Carousel.slide1.link',
    },
    {
        bgImg: home2,
        heading: 'FeaturedEvents.Carousel.slide2.heading',
        subHeading: 'FeaturedEvents.Carousel.slide2.subheading',
        ctaUrl: '/home/',
        cta: 'FeaturedEvents.Carousel.slide2.link',
    },
    {
        bgImg: home3,
        heading: 'FeaturedEvents.Carousel.slide3.heading',
        subHeading: 'FeaturedEvents.Carousel.slide3.subheading',
        ctaUrl: '/home/',
        cta: 'FeaturedEvents.Carousel.slide3.link',
    },
    {
        bgImg: home4,
        heading: 'FeaturedEvents.Carousel.slide4.heading',
        subHeading: 'FeaturedEvents.Carousel.slide4.subheading',
        ctaUrl: '/home/',
        cta: 'FeaturedEvents.Carousel.slide4.link',
    },
  ];
  return (
    <CommonGrid as="section">
      <el.CopyContainer>
        <el.FlexRow>
          <HR width="20%" margin="0 10px 0 0" />
          <Heading as="h4" capitalized>
            Event
          </Heading>
        </el.FlexRow>
        <Heading as="h2" large>
          Ensemble vers l'&eacute;galit&eacute;
        </Heading>
        <Heading medium>
          Celebrating women in IT on International Women's Day #BalanceforBetter
        </Heading>
        <el.FlexRow>
          <HR
            width="40%"
            margin="0 10px 0 0"
            borderColor={branding.secondary}
          />
          <Heading as="h4" color={branding.secondary}>
            march 08, 2019
          </Heading>
        </el.FlexRow>
        <P spacedOut>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit,
          accusamus? Quibusdam ipsa voluptas porro, corrupti sed vitae quidem
          molestias fuga ea quos, quisquam quaerat ut?
        </P>
      </el.CopyContainer>
      <el.CarouselContainer>
        <Carousel slides={carouselData} color="#fff" />
      </el.CarouselContainer>
      <el.FauxFooter />
    </CommonGrid>
  );
}
