import React from 'react';
import styled from 'styled-components';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import * as vars from '../../constants';

export const ArticleCardContainer = styled.article`
  height: 100%;
  width: 100%;
  background: url("${p => p.bgImg}") center center no-repeat;
  background-size: cover;
`;
export const ShadowOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 73%,
    rgba(0, 0, 0, 0.34) 87%,
    rgba(0, 0, 0, 0.65) 100%
  );
`;
export const InfoContainer = styled.div`
  padding: 20px;
  color: ${vars.branding.grey3};
  background-color: rgba(255, 255, 255, 0.15);
  width: 80%;
`;
export const TopRuler = styled.hr`
  width: 40%;
  border-top: 1px solid ${vars.branding.grey3};
  margin: 0 0 5px 0;
`;
export const CardLink = styled(props => <LocalizedLink {...props} />)`
  color: ${vars.branding.primaryLight};
  font-size: 0.75rem;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
