import React, { Component } from 'react';
import { HR, GatsbyLink } from '../shared-styled-components/globals.styled';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import intactLogo from '../../images/IntactLab_nobulb.svg';
import * as el from './HomeIntro.styled';
import {Heading} from '../shared-styled-components/typography';

import home1 from '../../images/Hero_1.jpg';
import home2 from '../../images/Hero_2.jpg';
import home3 from '../../images/Hero_3.jpg';
import home4 from '../../images/Hero_4.jpg';
import Carousel from '../Carousel/Carousel.component';
import { branding } from '../../constants';

export default class HomeIntro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0
        };

        this.carouselData = [
            {
                bgImg: home1,
                heading: 'HomeIntro.Carousel.slide1.heading',
                subHeading: 'HomeIntro.Carousel.slide1.subheading',
                ctaUrl: '/home/',
                cta: 'HomeIntro.Carousel.slide1.link',
            },
            {
                bgImg: home2,
                heading: 'HomeIntro.Carousel.slide2.heading',
                subHeading: 'HomeIntro.Carousel.slide2.subheading',
                ctaUrl: '/home/',
                cta: 'HomeIntro.Carousel.slide2.link',
            },
            {
                bgImg: home3,
                heading: 'HomeIntro.Carousel.slide3.heading',
                subHeading: 'HomeIntro.Carousel.slide3.subheading',
                ctaUrl: '/home/',
                cta: 'HomeIntro.Carousel.slide3.link',
            },
            {
                bgImg: home4,
                heading: 'HomeIntro.Carousel.slide4.heading',
                subHeading: 'HomeIntro.Carousel.slide4.subheading',
                ctaUrl: '/home/',
                cta: 'HomeIntro.Carousel.slide4.link',
            },
        ];
    }

    componentDidMount() {
        window.addEventListener('scroll', this.parallaxShift);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.parallaxShift);
    }

    parallaxShift = () => {
        this.setState({
            offset: window.pageYOffset
        });
    };

    render() {
        return (
            <>
            <el.HomeIntroGrid>
                <el.IntroCopyContainer>
                    <el.TopContent>
                        <el.IntactLogo src={intactLogo}/>
                        <Heading as="h2" light large margin="0 0 40px 0">
                            <FormattedMessage id="HomeIntro.IntroCopy.heading"/>
                        </Heading>
                    </el.TopContent>
                    <el.BottomContent>
                        <HR width="100px" margin="0 0 15px 0"/>
                        <Heading as="h4" capitalized small margin="0 0 10px 0">
                            <FormattedHTMLMessage id="HomeIntro.BottomContent.heading"/>
                        </Heading>
                        <GatsbyLink to="/page-2">
                            <FormattedMessage id="HomeIntro.BottomContent.link"/>
                        </GatsbyLink>
                    </el.BottomContent>
                </el.IntroCopyContainer>
                <el.HeroImageContainer>
                    <Carousel slides={this.carouselData} color={branding.secondary}/>
                </el.HeroImageContainer>
                <el.BackgroundUnderlay/>
            </el.HomeIntroGrid>
            <el.HeroTextGrid>
                <el.HeroText1
                    style={{paddingLeft: (this.state.offset / 2) + 'px'}}
                >
                    <FormattedMessage id="HomeIntro.HeroText.text1"/>
                </el.HeroText1>
                <el.HeroText2
                    style={{left: -this.state.offset / 2 + 'px'}}
                >
                    <FormattedHTMLMessage id="HomeIntro.HeroText.text2"/>
                </el.HeroText2>
                <el.HeroTextUnderlay/>
            </el.HeroTextGrid>
            </>
        );
    }
}
